<template>
  <a-card :bordered="true" class="header-solid h-full forum-list" >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">
            <a-tooltip placement="top">
              <template slot="title">
                <span>Refresh Feeds</span>
              </template>
              <a-button type="link" @click="resetTable" class="icon-btn" style="color: black">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-refresh-cw">
                  <polyline points="23 4 23 10 17 10"></polyline>
                  <polyline points="1 20 1 14 7 14"></polyline>
                  <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                </svg>
              </a-button>
            </a-tooltip>
            Forum Feeds
          </h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-button type="primary" @click="selectedItem= {},showModal=true">
            <a-icon type="plus-circle" theme="outlined"/>
            ADD NEW
          </a-button>
        </a-col>
      </a-row>
      <FormModal v-if="showModal" :visible="showModal" :selectedItem="selectedItem" :editMode="editMode"
                 @formSuccess="formSuccess" @cancel="cancelForm"></FormModal>
      <a-row type="flex" align="middle" style="margin-top: 12px">
        <a-col :span="24" :md="12">

        </a-col>
      <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
        <a-input-search placeholder="Search Forum Title" v-model="search" @input="loadingSearch=true,pagination.current=1,$router.push({name:'Forum Feeds',query:{search:search}})" :loading="loadingSearch" />
        </a-col>
      </a-row>
    </template>
  <a-list item-layout="vertical" size="large" :loading="loading" :pagination="pagination" :data-source="listData">
    <a-list-item slot="renderItem" key="item.title" slot-scope="item, index">
      <a-list-item-meta :description="(moment().diff(moment(item.createdAt),'days')<7?(moment().diff(moment(item.createdAt),'days')<1?(moment(item.createdAt).fromNow()):(moment(item.createdAt).fromNow()+' at '+moment(item.createdAt).format('h:mm a'))):(moment(item.createdAt).format('D MMM YYYY, hh:mm A')))+(' by '+item.user.name)">
        <router-link slot="title" :to="{
        name: 'Forum Discussion',
        params: { id: item._id }
      }" >
          {{ item.title }}
        </router-link>
        <a-avatar v-if="item.user.imageUrl" slot="avatar" :src="item.user.imageUrl" />
        <a-avatar v-else slot="avatar" style="background-color: #1890ff">{{ item.user.name|getNameForAvatar}}</a-avatar>
      </a-list-item-meta>

      <read-more more-str="Read full" style="margin-left: 10px" :text="item.description" link="#" less-str="View less" :max-chars="300"></read-more>
      <div style="display: flex;justify-content:space-between;margin-top: 10px;margin-left: 10px">
        <div style="display: flex;margin-top: 10px;align-items: center;">
                <a-icon type="message" style="margin-right: 8px" />
                {{ item.repliesCount?item.repliesCount:'No' }} {{item.repliesCount==1?'Reply':'Replies'}}
        </div>
        <div class="" v-if="item.replies.length">
          <a-space :size="-12" class="avatar-chips">
            <a-tooltip  v-for="(reply, index) in $options.filters.filterUniqueUsers(item.replies)" :key="index" :title="reply.user.name">
              <a-avatar v-if="reply.user.imageUrl" :src="reply.user.imageUrl" />
              <a-avatar v-else style="background-color: #1890ff" >{{ reply.user.name|getNameForAvatar}}</a-avatar>
            </a-tooltip>
          </a-space>

        </div>
      </div>
    </a-list-item>
  </a-list>
  </a-card>
</template>
<script>
import debounce from 'lodash/debounce';
import FormModal from "./FormModal";
import moment from "moment";
import ReadMore from 'vue-read-more';
import Vue from 'vue'
Vue.use(ReadMore)

export default {
  data() {
    this.fetchForumDebounce = debounce(this.fetchForumDebounce, 800);
    return {
      moment,
      loadingSearch:false,
      search:'',
      listData:[],
      pagination: {
        onChange: page => {
          this.$router.push({name:'Forum Feeds',query:{page:page,search:this.$route.query.search}})
          this.handleTableChange(page)
        },
        current:this.$route.query.page?this.$route.query.page:1,
        pageSize: 10,
      },
      status:'All',
      url: process.env.VUE_APP_SERVER_URL,
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchInput: null,
      selectedItem: {},
      showModal: false,
      editMode: false,
      data: [],
      loading: false,
      authorsHeaderBtns: 'all',
      queryData: params => {
        return this.$store.dispatch("ALL_FORUMS", {params: params});
      },
      team: [
        "images/face-1.jpg",
        "images/face-4.jpg",
        "images/face-2.jpg",
        "images/face-3.jpg",
      ],
      actions: [
        // { type: 'like-o', text: '156',title:'Like' },
        // { type: 'dislike-o', text: '156',title:'Dislike' },
        { type: 'message', text: '2',title:'Reply' },
      ],
    };
  },
  watch:{
    '$route.query.search'(){
      this.fetchForumDebounce()
    }
  },
  components:{FormModal},
  mounted() {
    this.search=this.$route.query.search?this.$route.query.search:''

    this.fetch();
  },
  methods:{
    fetchForumDebounce(){
      this.fetch()
    },
    handleTableChange(page) {
      const pager = {...this.pagination};
      pager.current = page;
      this.pagination = pager;
      // this.filteredInfo = filters;
      // this.sortedInfo = sorter;
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        // sortField: sorter.field,
        // sortOrder: sorter.order,
        // ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.status=this.status
      params.search=this.search
      this.queryData({
        results: this.pagination.pageSize ? this.pagination.pageSize : 10,
        page: this.pagination.current ? this.pagination.current : 1,
        ...params,
      }).then(({data}) => {
        const pagination = {...this.pagination};
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.listData = data.results;
        this.pagination = pagination;
      })
          .finally(()=>{
            this.loadingSearch=false
            this.$forceUpdate()
          });
    },

    getForumsList(){
      this.loading=true
      setTimeout(()=>{
        this.loading=false
      },2000)
    },
    resetTable() {
      // this.handleReset()
      // this.searchText = "";
      // this.filteredInfo = null;
      // this.sortedInfo = null;
      // this.pagination.current = 1;
      // this.pagination.pageSize = 10;
      // // Object.assign(this.pagination,{})
      this.fetch()
    },
    formSuccess() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
      this.fetch()
    },
    cancelForm() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
    },
  }
};
</script>
<style >
.forum-list .ant-list-vertical .ant-list-item-meta-title{
  margin-bottom:0 ;
  margin-top: -2px!important;

}
.forum-list .ant-list-vertical .ant-list-item-meta{
  margin-bottom: 10px;
}
.forum-list .ant-list-item-meta-description{
  font-weight: 600;
}
.forum-list  .ant-spin-nested-loading{
  min-height: 300px!important;
}
</style>
